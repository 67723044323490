import React from 'react';
import MESSAGES from '@messages';
import SchedulePage from '../templates/schedule-page';

const Harmonogram = () => (
  <>
    <SchedulePage
      title={MESSAGES.SCHEDULE_TITLE}
      metaTitle={MESSAGES.SCHEDULE_META_TITLE}
      id="clmgp4ikq0005mqz0qbo4hsze"
    />
  </>
);

export default Harmonogram;
